<template>
  <div class="recycled">

    <myHeader :query="query" @search="searchChange"/>

    <!-- 一排按钮 -->
     <div class="buttonArr">
       <el-button class="blueBoder" :loading="restoreLoading"  size="small" @click="batchRestore" v-p="['externaladmin:noticeList:recycled:batchRestore']">批量还原</el-button>
      <el-button class="blueBoder" :loading="deleteLoading" size="small" @click="batchDelete" v-p="['externaladmin:noticeList:recycled:batchDelete']">批量删除</el-button>
    </div>

     <!-- 表格 -->
     <CommonTable :tableLoading="tableLoading" height="auto" :cols="cols" :infoData="data"  @selection-change="handleSelectionChange">
       <!-- 表格标题样式 -->
       <template #templateName="{scoped:row}">
         <div class="templateName" @click="showNotice(row)">{{row.title}}</div>
       </template>
      <!-- 操作列 -->
       <el-table-column fixed="right" label="操作" width="350px" align="center">
         <template slot-scope="{ row }">
             <color-text-btn class="update-btn" :loading="row.restoreLoading" size="mini" @click="restore(row)" v-p="['externaladmin:noticeList:recycled:restore']">还原</color-text-btn>
            <el-divider direction="vertical"></el-divider>
            <Popover @sureHandler="onDelete(row)">
                <template #tip>
                  <p>确定删除?</p>
                </template>
                <template #reference>
                  <color-text-btn class="update-btn" :loading="row.deleteLoading" size="mini" type="danger" v-p="['externaladmin:noticeList:recycled:delete']">删除</color-text-btn>
                </template>
            </Popover>
         </template>
       </el-table-column>
    </CommonTable>

    <!-- 分页器 -->
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />

    <!-- 查看公告对话框 -->
    <el-dialog
      title="查看详情"
      :visible="noticeVisible"
      width="1000px"
      :before-close="noticehandleClose"
      class="articleWarpper"
    >
      <div class="articleContent" v-loading="noticeLoading">
      <!-- 标题 -->
      <div class="articleContent_title" v-html="articleData.title">
      </div>
      <!-- 类型 -->
      <div class="articleContent_line2">
        <!-- <div class="articleContent_line2_type">分类:<span v-html="articleData.categoryName"></span></div>
         <div class="articleContent_line2_time">时间:<span v-html="articleData.createTime"></span></div> -->
      </div>
      <!-- 内容 -->
      <div class="articleContent_content" v-html="articleData.content">
      </div>

    </div>
     </el-dialog>
  </div>
</template>

<script>
import {cols} from './cols'
import myHeader from './module/header.vue'
import { initDataMixin } from '@/mixins'
import { noticeContentDetail,noticeContentDelete,noticeContentRestore}from '@/api/helpcenter/helpcenter'
export default {
   mixins: [initDataMixin],
  components:{
    myHeader
  },
  data(){
    return{
      url:'/externaladmin/systemService/noticeContentList',
      query:{
        status:2,
        orderItems: [{asc: false, column: "delete_time"}]
      },
      cols,
      // 查看文章对话框显示控制articleVisible
      noticeVisible:false,
      articleData:{},
      loading:false,
      restoreLoading:false,
      deleteLoading:false,
      multiSelection:[],
      noticeLoading:false
    }
  },
  methods:{
     // 表格勾选内容改变触发
    handleSelectionChange(val){
      this.multiSelection = val
    },
    // 删除公告
    async onDelete(row){
      const idList = []
      idList.push(row.id)
      try {
        this.$set(row,'deleteLoading',true)
        const {code} = await noticeContentDelete({idList})
         if ($SUC({ code })) {
           this.$message.success('删除公告成功')
           this.init()
          } else {
            this.$message.error('删除公告失败')
          }
        }catch(e){
          this.$message.error('删除公告失败')
        }finally{
          this.$set(row,'deleteLoading',true)
        }
    },
    // 批量删除公告
    async batchDelete(){
      if(this.multiSelection.length<1) return this.$message.warning('请先选择数据')
      const idList =[]
      this.multiSelection.map(item =>{
        idList.push(item.id)
      })
      try {
        this.deleteLoading = true
        const {code} = await noticeContentDelete({idList})
         if ($SUC({ code })) {
           this.$message.success('批量删除公告成功')
           this.init()
          } else {
            this.$message.error('批量删除公告失败')
          }
        }catch(e){
          this.$message.error('批量删除公告失败')
        }finally{
          this.deleteLoading = false
        }

    },
    // 批量还原公告
    async batchRestore(){
      if(this.multiSelection.length<1) return this.$message.warning('请先选择数据')
      const idList =[]
      this.multiSelection.map(item =>{
        idList.push(item.id)
      })
      try {
        this.restoreLoading = true
        const {code} = await noticeContentRestore({idList})
         if ($SUC({ code })) {
           this.$message.success('批量还原公告成功')
           this.init()
          } else {
            this.$message.error('批量还原公告失败')
          }
        }catch(e){
          this.$message.error('批量还原公告失败')
        }finally{
          this.restoreLoading = false
        }

    },
    async restore(row){
       const idList = []
      idList.push(row.id)
      try {
        this.$set(row,'restoreLoading',true)
        const {code} = await noticeContentRestore({idList})
         if ($SUC({ code })) {
           this.$message.success('还原公告成功')
           this.init()
          } else {
            this.$message.error('还原公告失败')
          }
        }catch(e){
          this.$message.error('还原公告失败')
        }finally{
          this.$set(row,'restoreLoading',true)
        }
    },
    // 查看文章对话框关闭noticehandleClose
    noticehandleClose(){
        this.noticeVisible = false
    },
    // 查看文章对话框开启showNotice
    async showNotice(row){
      this.noticeLoading = true
      this.noticeVisible = true
      try{
        const {code,detail} = await noticeContentDetail({id:row.id})
        if($SUC({code})){
          this.articleData = detail
        }else{
          this.$message.error('获取数据失败')
        }
      }catch(e){
        this.$message.error('获取数据失败')
      }finally{
        this.noticeLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.recycled{
  .color-btn-component{
    font-size: 14px;
  }
  // 一排按钮
  .buttonArr{
    margin-bottom: 28px;
    .blueBoder{
      border-color: #3841DB;
      color: #3841DB;
    }
  }
  // 表格标题样式
  .templateName{
    color: #3841DB;
    cursor: pointer;
  }
   // 表格内编辑.primary
  .primary {
      color: #3841db !important;
      cursor: pointer;
    }
       // 查看文章对话框
  .articleWarpper{
    ::v-deep {
      .el-dialog__title {
        font-size: 16px;
        color: #495060;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
    }
    .articleContent{
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      // 标题
      .articleContent_title{
        margin: 16px 0 20px 0;
        color: #595961;
        font-size: 20px;
      }
      // 类型
      .articleContent_line2{
        display: flex;
        color: #A1A1A1;
        font-size: 14px;
        margin-bottom: 53px;
        .articleContent_line2_type{
          margin-right: 26px;
        }
      }
      // 内容
      .articleContent_content{
        width: 80%;
      }
    }
  }
}
</style>
