export const cols = [
  {
    label: '公告标题',
    minWidth: '200',
    slotName: 'templateName'
  },
  {
    label: '删除时间',
    minWidth: '200',
    prop: 'deleteTime'
  }
]
